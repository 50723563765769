import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { ApiPost } from '../../../api'

class ParameterController extends Component {
    constructor(props) {
        super(props);

    }

    

    componentDidMount() {
        /*
        var stationParams = {
            ControllerId: 3
        }
        ApiPost('Monitoring', 'GetParamInfo', stationParams)
        .then((data) => {
            this.setState({ paramInfo: data }, this.props.handleData({ paramInfo: data, paramVals: null }));
        })
        */
    }

    mapStationOptions() {
        var options = [];
        this.props.header.assets.map(x => {
            if (x.controllerid == this.props.selectedController)
                options.push({ label: x.name, value: x.id });
        })
        return options;
    }

    

    render() {
        const { selectChange, controllerChange, sitesCsv } = this.props
        return (
            <div>
                <Row>
                    <Col lg={3} xs={6}>
                        <select className="form-control" onChange={controllerChange} >
                            <option value={0}>Select Controller</option>
                            <option value={1}>VMP4400</option>
                            <option value={2}>TMC2</option>
                            <option value={3}>TMC3 - Nordex</option>
                            <option value={6}>TMC3 - NDK</option>
                            <option value={7}>VMP3500</option>
                            <option value={8}>TMC3 - Bonus</option>
                        </select>
                    </Col>
                    <Col lg={1} xs={5}>
                    </Col>
                    <Col lg={3} xs={6}>
                    </Col>
                    <Col lg={5} xs={12}>
                        <Select
                            arrowRenderer={arrowRenderer}
                            closeOnSelect={true}
                            disabled={false}
                            multi
                            onChange={selectChange}
                            options={this.mapStationOptions()}
                            placeholder="Select site(s)"
                            removeSelected={false}
                            clearable={false}
                            simpleValue
                            value={sitesCsv} />
                    </Col>
                </Row>

            </div>
        );
    }

};

function arrowRenderer() {
    return (
        <i className="fa fa-plus" style={{color:'#464a4c'}}/>
    );
}

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(ParameterController)
