import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
//import MasterDetail from '../../../testComp/MasterDetail/src'
import { listRequest } from '../../../configuration/enums';
import CaseSelector from '../../../components/CaseSelector';
import DropDatePicker from '../../../components/DropDatePicker';
import moment from 'moment'
import { dbTables } from '../../../configuration/formTables'
import IncidentViz from './IncidentViz'
import CalendarModal from '../../Activity/components/CalendarModal'
import { ApiGet, ApiPost } from '../../../api'
export const API_PATH = process.env.SERVER_PATH + "/api";

class Incidents extends Component {
    constructor(props) {
        super(props);
        this.handleDate = this.handleDate.bind(this);
        var date = new Date();
        //var date = new Date('2020-11-29');
        this.state = {
            data: null, columns: null, subcomponents: null, complete: false, activeTab: '1', caseModalOpen: false, guid: null,
            fromDate: this.props.match.params.id ? moment(date).add(-7, 'days').startOf('day') : moment(date).add(-1, 'days').startOf('day'),
            toDate: moment(date.getTime()).endOf('day'), mdRec: [], groupFilter: null, job: null, dataLoaded: false
        };
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        var listsToGet = { ListIds: JSON.stringify([listRequest.FieldEngineer, listRequest.OpenJobs, listRequest.WorkCardTypes, listRequest.WorkCardStatuses, listRequest.CalendarEventTypes, listRequest.CustomerWithSites]) }
        ApiGet('Lists', 'GetList', listsToGet)
        .then((data) => {
            this.setState({ dataLoaded: true, users: data[listRequest.FieldEngineer], jobList: data[listRequest.OpenJobs], eventTypes: this.state.enabledContracts ? data[listRequest.WorkCardTypes].filter(x => this.state.enabledContracts.includes(x.id)) : data[listRequest.WorkCardTypes], workStatuses: data[listRequest.WorkCardStatuses], calEventTypes: data[listRequest.CalendarEventTypes], customerWithSites: data[listRequest.CustomerWithSites] })
        })
    }

    goNav(rec, location) {
        this.props.history.push(location)
    }

    toggleCaseModal(modalId, rec, e) {
        var wGuid = null;
        if (rec) {
            wGuid = rec.wcardGuid;
        }
        this.setState({ [modalId]: !this.state[modalId], guid: wGuid })
    }

    toggle(activeTab) {
        if (this.state.activeTab !== activeTab) {
            this.setState({ activeTab });
        }
    }

    handleDate(fromDate, toDate, custom) {
        this.setState({ fromDate, toDate, groupFilter: null, dateSelectTitle: moment(fromDate).format('DD/MM/YY') + ' to ' + moment(toDate).format('DD/MM/YY') })
    }

    parseMDData(e) {
        if (e.data.length != this.state.mdRec.length) {
            this.setState({ mdRec: e.data })
        }
        else if (('supp_143' in e.data) && (!('supp_143' in this.state.mdRec))) {
            this.setState({ mdRec: e.data, fromDate: moment(e.data.raised) })
            if (e.data.closed) {
                this.setState({ toDate: moment(e.data.closed) })
            }
        }
    }

    setGroupRef(ref) {
        this.setState({ groupFilter : ref })
    }

    componentWillUnmount() {
        this.setState({ mdRec: [], groupFilter: null })
    }

    addLog() {
        var nDate = moment().format('DD/MM/YY')
        console.log('stit', this.state)
        var job = { activityDate: nDate, calId: 9999, deviceId: this.state.mdRec.stationId, newEvent: true, users: null, preSelect: false, adHoc: true, workcard: { engineerLogId: 9999 }, calEventType: 1 }
        this.setState({ job })
    }

    saveEvent(a,e) {
        ApiPost('Calendar', 'CreateCalEvent', { activityDate: e.activityDate, deviceId: e.deviceId, eType: e.newEventType, engineer: e.workcard && e.workcard.engineers ? e.workcard.engineers[0] : null, jobStatus: e.jobStatus, eWorkCard: e.eWorkCard, eventStartTime: e.eventStartTime, eventEndTime: e.eventEndTime, calEventType: e.calEventType, note: e.note, calId: e.calId, newEvent: e.newEvent, workCarriedOut: e.workCarriedOut, fsrStatus: e.fsrStatus, dueBy: e.dueBy, users: e.users, incidentId: this.state.mdRec.id })
        .then((data) => {
            console.log('event saved',data)
        })
    }

    render() {

        var incidentsFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none', section: 'Case' },
            { label: "GroupId", fieldId: "groupId", display: 'none' },
            { label: "GroupRef", fieldId: "groupRef", display: 'none', canFilter: true },
            { label: "GeoData", fieldId: "geoData", display: 'none' },
            { label: "", fieldId: "title", display: 'none', section: 'Case' },
            { label: "Alert Type", fieldId: "alertTypeId", canFilter: true, display: 'grid', list: listRequest.AlertTypes, section: 'Case', validation: 'required' },
            { label: "Alert Type", fieldId: "alertTypeId", canFilter: true, display:'form', list: listRequest.faultTypes, section: 'Case', validation: 'required' },
            { label: "Asset", width: 110, fieldId: "stationId", list: listRequest.windsites, section: 'Case', type: 'readOnly', display: 'grid' },
            { label: "Case", fieldId: "caseRef", canFilter: true, section: 'Case', display: 'grid' },
            { label: "caseId", fieldId: "caseId", getId: -1, display:'none'},
            { label: "Operator", width: 105, fieldId: "windNiUserId", canFilter: true, list: listRequest.operators, section: 'Case', validation: 'required' },
            { label: "Do not track", fieldId: "noTrack", type: 'checkbox', section: 'Case', display: 'form', displayIf: (rec) => rec.alertTypeId < 50 },
            { label: "", fieldId: "caseId", type: 'custom', section: 'Case', display: 'form', custom: CaseSelector, validation: 'required', displayIf: (rec) => rec.alertTypeId < 50 && rec.noTrack != true},
            //{ label: "Notes", fieldId: "notes", type: 'readOnly', section: 'Fault' },
            { label: "Log Message", width: 200, fieldId: "cntrLogMsg", type: 'readOnly', section: 'Fault', canFilter: true },
            { label: "Controller", fieldId: "controller", display: 'form', type: 'readOnly', section: 'Fault' },
            { label: "Error Num", fieldId: "cntrErrNum", type: 'readOnly', display: 'form', section: 'Fault' },
            { label: "Monitoring", fieldId: "cntrMonitoring", type: 'readOnly', display: 'form', section: 'Fault' },
            { label: "Signal", fieldId: "cntrSignal", type: 'readOnly', display: 'form', section: 'Fault' },
            { label: "Fault Criteria", fieldId: "cntrCriterion", display: 'form', type: 'readOnly', section: 'Fault' },
            { label: "Reaction", fieldId: "cntrReact", display: 'form', type: 'readOnly', section: 'Fault' },
            { label: "Acknowledge", fieldId: "cntrAck", display: 'form', type: 'readOnly', section: 'Fault' },
            { label: "Raised", fieldId: "raised", type: 'datetime', display: 'form', section: 'Time Frame', validation: 'required' },
            { label: "Closed", fieldId: "closed", type: 'datetime', display: 'form', section: 'Time Frame' },
            { label: "Downtime (Hrs)", fieldId: "downtime", type: 'readOnly', display: 'form', section: 'Time Frame' },
            { label: "Alert Period", width: 180, fieldId: "range", display: 'grid', type: 'dateRange', typeProps: { sDate: 'raised', eDate: 'closed', showDuration: true } },
            { label: "Discount Downtime", fieldId: "isService", type: 'checkbox', display: 'form', section: 'Time Frame' },
            { label: "", fieldId: "navButton", width: 70, display: 'grid', type: 'navButton' },
        ];

        var logFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'grid', display: 'none' },
            { label: "Response Level", fieldId: "level", type: 'responseLevel', width: 120 },
            { label: "Operator", fieldId: "name", list: listRequest.operators, width: 150 },
            { label: "Updated", fieldId: "startDate", type: 'datetime', width: 120 },
            { label: "Notes", fieldId: "notes" },
            { label: "wcardGuid", fieldId: "wcardGuid", display: 'form' },
            { label: "", fieldId: "navButton", display: 'grid', type: 'navButton', typeProps: { location: '/Workcards/' }, width: 70 }
        ];

        var incidentsConfig = {
            model: 'incidents',
            crudParams: { fromDate: moment(this.state.fromDate).format('DD-MM-YYYY HH:mm'), toDate: moment(this.state.toDate).format('DD-MM-YYYY HH:mm') },
            fields: incidentsFields,
            master: true,
            showFilterRow: true,
            titleField: 'title',
            //cascade: true,
            gridButton: false,
            submitAction: () => { },
            formActions: [
                { action: 'delete' },
                { action: 'save', label: 'Save', icon: 'far fa-arrow-alt-left', after: () => this.props.history.go(-1) }, 
                { action: 'save' }
            ],
            gridProps: { defaultPageSize: 20 },
            linkedEntity: dbTables.Alerts,
            mdData: (e) => this.parseMDData(e),
            customFilter: this.state.groupFilter && [{ id: "groupRef", value: this.state.groupFilter }],
            defaultSorted: [{ id: 'raised' }],
            serversidePaging: true,
            apiPath: API_PATH
        }

        var incidentLogConfig = {
            model: 'incidentWCs',
            //path: '/Incidents/GetIncidentLogs/[*id*]',
            path: '/Incidents/GetIncidentLogs/' + this.props.match.params.id,
            fields: logFields,
            gridHeader: 'Log Responses',
            gridButton: [{ pathname: '/WorkCards/New', state: { incidentId: this.props.match.params.id, responseLevel: 18, mdTitle: 'Add Note' }, icon: 'fal fa-sticky-note' }],
            gridProps: { defaultPageSize: 5 },
            linkedEntity: dbTables.Workcards,
            apiPath: API_PATH
        }

        return (<div>
            <div className='titleBlock'>Alerts</div>
            {this.props.match.params.id == undefined && this.state.mdRec.length > 0 && <IncidentViz data={this.state.mdRec} fromDate={this.state.fromDate} toDate={this.state.toDate} setGroupRef={(e) => this.setGroupRef(e)}/>}
            <MasterDetail key='mdInc' {...incidentsConfig} headerControl={!this.props.match.params.id ? <DropDatePicker key='ddpInc' right handleDate={this.handleDate} startDate={this.state.fromDate} endDate={this.state.toDate} /> : null}>
                {this.props.match.params.id != 'New' &&
                    <div>
                        {this.state.mdRec.supp_143 && <IncidentViz data={[this.state.mdRec]} fromDate={this.state.fromDate} toDate={this.state.toDate} setGroupRef={(e) => this.setGroupRef(e)} />}
                        <MasterDetail {...incidentLogConfig} headerControl={this.state.dataLoaded && <button key='viewBtn' className="btn btn-default" onClick={() => this.addLog()}><i className='fa fa-plus' /></button>} />
                    </div>}
            </MasterDetail>
            {this.state.job && <CalendarModal {...this.state} job={this.state.job} cancel={(e) => this.setState({ job: null })} saveEvent={(e, a) => this.saveEvent(e, a)} errorMsg='Complete all inputs' /> }
            </div>)

    }
}

export default (Incidents)
