import React, { Component } from 'react'
import { Col, Row, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import ActController from './ActivityController'
import classnames from 'classnames'
import ActCards from './ActivityCards'
import ActivityCalendar from './ActivityCalendar'
import { ApiPost, getConfigItem } from '../../../api';
import { connect } from 'react-redux';
import { saveActivityFilter } from '../../../actions/header';
import DropDatePicker from '../../../components/DropDatePicker'
import moment from 'moment'
import OverlayLoader from '../../../components/OverlayLoader'
import { listRequest } from '../../../configuration/enums'
import { ApiGet } from '../../../api/index'

class ActivityContainer extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        var phaf = props.header.activityFilter
        this.state = {
            mode: phaf && phaf.mode ? phaf.mode : null,
            data: null,
            showFilter: false,
            filterTitle: 'All Activity Last 7 days',
            mainActiveTab: phaf && phaf.tab ? phaf.tab : '2',
            users: null,
            jobList: null,
            eventTypes: null,
            mainActiveYear: phaf && phaf.activeYear ? phaf.activeYear : null,
            mainActiveWeek: phaf && phaf.activeWeek ? phaf.activeWeek : null,
            mainActiveMonth: phaf && phaf.activeMonth ? phaf.activeMonth : null,
            fromDate: phaf && phaf.fromDate ? phaf.fromDate : moment(date).add(this.props.activityType == 1 ? 0 : -7, 'days').endOf('day'),
            toDate: phaf && phaf.toDate ? phaf.toDate : moment(date.getTime()).add(this.props.activityType == 1 ? 7 : 0, 'days').endOf('day'),
            engineerSiteToggle: phaf && phaf.toggle ? phaf.toggle : null,
            enabledContracts: getConfigItem('$.general.enabledContracts') || null,
            dateChanged: false
        }

        this.handleData = this.handleData.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.onWeekChange = this.onWeekChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onSiteUserToggle = this.onSiteUserToggle.bind(this);
    }

    componentDidMount() {
        document.title = 'Calendar | izon'
        if (this.state.mainActiveWeek) {
            this.onWeekChange(this.state.mainActiveWeek, this.state.mainActiveYear)
        }
        else if (this.state.mainActiveMonth) {
            this.onMonthChange(this.state.mainActiveMonth, this.state.mainActiveYear)
        }
        else if (this.state.mainActiveYear) {
            this.onYearChange(this.state.mainActiveYear)
        }
        else {
            this.toggle(this.state.mainActiveTab, 'main')
        }
        this.getInitData()
    }

    reloadData() {
        console.log('reloadData')
        this.child.getActivityData()
    }

    getInitData() {
        var listsToGet = { ListIds: JSON.stringify([listRequest.FieldEngineer, listRequest.OpenJobs, listRequest.WorkCardTypes, listRequest.WorkCardStatuses, listRequest.CalendarEventTypes, listRequest.CustomerWithSites]) }
        console.log('listsToGet', listsToGet)
        ApiGet('Lists', 'GetList', listsToGet)
        .then((data) => {
            var customerWithSites = data[listRequest.CustomerWithSites]
            this.setState({ users: data[listRequest.FieldEngineer], jobList: data[listRequest.OpenJobs], eventTypes: this.state.enabledContracts ? data[listRequest.WorkCardTypes].filter(x => this.state.enabledContracts.includes(x.id)) : data[listRequest.WorkCardTypes], workStatuses: data[listRequest.WorkCardStatuses], calEventTypes: data[listRequest.CalendarEventTypes], customerWithSites })
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.header.filterToken != this.props.header.filterToken) {
            this.getInitData()
        }
    }

    filterList(data, filterBy) {
        var filteredData = data
        if (this.props.header && this.props.header.activityFilter && this.props.header.activityFilter[filterBy]) {
            //console.log('np.header.activityFilter', this.props.header.activityFilter)
            //console.log({ data, filterBy })

            filteredData = data.filter(x => this.props.header.activityFilter[filterBy].includes(x.id))
            //console.log('filteredData', filteredData)
        }
        else {
            //console.log('cant filter', this.props)
        }

        return filteredData
    }

    handleData(data) {
        //console.log('handlingdata', data)
        this.setState({ data, showFilter: false });     
    }

    updateTitle(data) {
        this.setState({ filterTitle: data })
    }

    onYearChange(activeYear) {
        var date = new Date();
        var y = date.getFullYear();
        this.setState({ mainActiveYear: activeYear, loaded: false})
        if (y == activeYear) {
            this.child.getActivityData(false, true, false, false, false, this.state.mainActiveWeek, activeYear);
            this.child.setTitle();
        }
        else {
            this.child.getActivityData(false, activeYear, false, false, false, this.state.mainActiveWeek, activeYear, this.state.mainActiveMonth);
            this.child.setTitle();
        }

        this.props.dispatch(saveActivityFilter({...this.props.header.activityFilter, ...{ activeYear }}))
    }

    onMonthChange(activeMonth, activeYear) {
        this.setState({ mainActiveMonth: activeMonth, mainActiveYear: activeYear, loaded: false, mode: 'month' })
        this.child.getActivityData(false, false, false, false, true, this.state.mainActiveWeek, activeYear, activeMonth);
        this.child.setTitle();

        this.props.dispatch(saveActivityFilter({...this.props.header.activityFilter, ...{ activeYear }, ...{ activeMonth}}))
    }

    onWeekChange(activeWeek, activeYear) {
        this.setState({ mainActiveWeek: activeWeek, mainActiveYear: activeYear, loaded: false, mode: 'week' })
        this.child.getActivityData(false, false, true, false, false, activeWeek, activeYear, this.state.mainActiveMonth)
        this.child.setTitle()

        this.props.dispatch(saveActivityFilter({...this.props.header.activityFilter, ...{ activeWeek }, ...{activeYear }}))
    }

    onFilterChange(attribName, value) {
        console.log('onFilterChange', this.props.header.activityFilter)
        this.props.dispatch(saveActivityFilter({ ...this.props.header.activityFilter, ...{ [attribName]: value } }, true))
    }

    onSiteUserToggle(toggle) {

        this.setState({ engineerSiteToggle: toggle, loaded: false})

        this.props.dispatch(saveActivityFilter({...this.props.header.activityFilter, ...{ toggle }}))
    }

    handleDate(fromDate, toDate) {
        var getData = false
        if (this.state.fromDate != fromDate || this.state.toDate != toDate) {
            getData = true
            this.setState({data: null, fromDate, toDate, dateChanged: true})
        }
        this.child.handleDate(fromDate, toDate, null, getData)
    }

    toggle(tab, type) {
        var mode
        if (type == 'main') {   
            if (tab == 4) {
                this.child.getActivityData(false, true, false, false, false, this.state.mainActiveWeek, this.state.mainActiveYear);
                this.child.setTitle();
                this.setState({ mainActiveTab: tab, mode: 'year', dateChanged: false });
                mode = 'year'
            }
            else if (tab == 3) {
                this.child.getActivityData(false, false, false, false, true, this.state.mainActiveWeek, this.state.mainActiveYear, this.state.mainActiveMonth);
                this.child.setTitle();
                this.setState({ mainActiveTab: tab, mode: 'month', dateChanged: false });
                mode = 'month'
            }
            else if (tab == 2) {
                this.child.getActivityData(false, false, true, false, false, this.state.mainActiveWeek, this.state.mainActiveYear);
                this.child.setTitle();
                this.setState({ mainActiveTab: tab, mode: 'week', dateChanged: false });
                mode = 'week'
            }
            else if (tab == 1) {
                this.child.getActivityData(false);
                this.child.setTitle();
                this.setState({ mainActiveTab: tab, dateChanged: false });
            }
            else {
                this.child.getActivityData(false, false, false, false, false, this.state.mainActiveWeek, this.state.mainActiveYear);
                this.child.setTitle();
                this.setState({ mainActiveTab: tab, dateChanged: false });
            }
            this.props.dispatch(saveActivityFilter({...this.props.header.activityFilter, ...{ tab }, ...{ mode }}))
        }
    }

    saveEvent(e) {
        console.log('saveEvent', e)
        ApiPost('Calendar', 'CreateCalEvent', { activityDate: e.activityDate, deviceId: e.deviceId, eType: e.newEventType, engineer: e.workcard && e.workcard.engineers ? e.workcard.engineers[0] : null, jobStatus: e.jobStatus, eWorkCard: e.eWorkCard, eventStartTime: e.eventStartTime, eventEndTime: e.eventEndTime, calEventType: e.calEventType, note: e.note, calId: e.calId, newEvent: e.newEvent, workCarriedOut: e.workCarriedOut, fsrStatus: e.fsrStatus, dueBy: e.dueBy, users: e.users, scheduledTime: e.scheduledTime })
        .then((data) => {
            //if (data.wcid != 0) {
            //    this.props.history.push('/Workcards/' + data.wcid, { history: null });
            //}
            //else {
                this.setState({ refreshed: !this.state.refreshed }, () => {
                    this.state.mode == 'week' ? this.onWeekChange(this.state.mainActiveWeek, this.state.mainActiveYear) : this.state.mode == 'month' && this.onMonthChange(this.state.mainActiveMonth, this.state.mainActiveYear)
                })
           //}
        })
    }

    removeEvent(calId, uId = null) {
        ApiPost('Calendar', 'RemoveCalEvent', { calId, uId })
        .then((data) => {
            var tempData = this.state.data
            if (uId == null) {
                tempData = tempData.filter(x => x.calId != calId)
            }
            else {
                var calIndex = tempData.findIndex(x => x.calId == calId)
                if (calIndex != null) {
                    var tempRec = tempData[calIndex]
                    tempRec.users = tempRec.users.filter(x => x != uId)
                    tempRec.usrsInfo = tempRec.usrsInfo.filter(x => x.userId != uId)
                    tempData = tempData.filter(x => x.calId != calId)
                    tempData.push(tempRec)
                }
            }
            this.setState({ data:tempData })
        })
    }

    render() {
        const { mainActiveTab, data, mode, users, dateChanged, engineerSiteToggle, mainActiveWeek, mainActiveMonth, mainActiveYear, jobList, eventTypes, workStatuses, calEventTypes, customerWithSites, addExternalEvent, fromDate, toDate } = this.state
        var isLoaded = false
        if (this.props.header.assets.length > 0 && users) {
            isLoaded = true
        }
        var startDate = fromDate, endDate = toDate
        if (!dateChanged && mainActiveWeek && mainActiveYear) {
            startDate = moment().day("Monday").week(mainActiveWeek).year(mainActiveYear).startOf('week')
            endDate = moment().day("Monday").week(mainActiveWeek).year(mainActiveYear).endOf('week')
        }
        var rowOrder = [], hideChecked = false
        if (this.child && this.child.state) {
            rowOrder = this.child.state.rowOrder && this.child.state.rowOrder
            hideChecked = this.child.state.hideChecked && this.child.state.hideChecked
        }
        return (
            <div>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col sm={2}><div className='titleBlock'>Calendar</div></Col>
                    <Col sm={9} className="filterTitle"><div>{!this.state.showFilter && this.child && this.child.getTitle()}</div></Col>
                    <Col xs={10} sm={1}><button type="button" className="btn btn-default" onClick={() => this.setState({ showFilter: !this.state.showFilter })}><i className="fal fa-filter" /></button></Col>
                </Row>
                <div style={{ display: this.state.showFilter ? 'block' : 'none' }}>
                    <ActController onRef={ref => (this.child = ref)} handleData={this.handleData} activityType={0} updateTitle={this.updateTitle} />
                </div>           
                <div>
                    <Nav tabs className="nav">
                        <NavItem>
                            <NavLink className={classnames({ active: mainActiveTab == '1' })} onClick={() => { this.toggle('1', 'main') }}>List</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: mainActiveTab == '2' })} onClick={() => { this.toggle('2', 'main') }}>Week</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: mainActiveTab == '3' })} onClick={() => { this.toggle('3', 'main') }}>Month</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent key='activityTabs' activeTab={mainActiveTab}>
                        {data && Array.isArray(data) && this.props.header && this.props.header.assets ?
                            [<TabPane key='tab1' tabId='1'>
                            {mainActiveTab == '1' && isLoaded &&
                                <div style={{ paddingTop: '5px' }}>
                                    <Row>
                                        <Col xs={8} sm={5} ><DropDatePicker width='260px' handleDate={(f, t) => this.handleDate(f, t)} startDate={startDate} endDate={endDate} /></Col>
                                    </Row>
                                    <div className='engineerContain'>
                                        <ActCards
                                            activityData={data}
                                            history={this.props.history}
                                            activityType={0}
                                            eventTypes={eventTypes}
                                            workStatuses={workStatuses}
                                            calEventTypes={calEventTypes}
                                            users={users}
                                            addExternalEvent={addExternalEvent}
                                            customerWithSites={customerWithSites}
                                            removeEvent={(e, u) => this.removeEvent(e, u)}
                                            reloadData={() => this.reloadData()}
                                            saveEvent={(e) => this.saveEvent(e)} />
                                    </div>
                                </div>}
                            </TabPane>,
                            <TabPane key='tab2' tabId='2'>
                                {mainActiveTab == '2' && isLoaded &&
                                    <ActivityCalendar
                                        onSiteUserToggle={(e) => this.onSiteUserToggle(e)}
                                        onWeekChange={(w, y) => this.onWeekChange(w, y)}
                                        onFilterChange={(t,r) => this.onFilterChange(t, r) }
                                        activityData={data}
                                        history={this.props.history}
                                        mode={mode}
                                        isCollapsible={false}
                                        engineerSiteToggle={engineerSiteToggle && engineerSiteToggle}
                                        mainActiveWeek={mainActiveWeek && mainActiveWeek}
                                        mainActiveYear={mainActiveYear && mainActiveYear}
                                        stations={this.props.header.assets}
                                        users={users}
                                        jobList={jobList}
                                        eventTypes={this.filterList(eventTypes, 'wcTypes')}
                                        workStatuses={workStatuses}
                                        calEventTypes={calEventTypes}
                                        customerWithSites={customerWithSites}
                                        saveEvent={(e) => this.saveEvent(e)}
                                        rowOrder={rowOrder}
                                        hideChecked={hideChecked }
                                        removeEvent={(e, u) => this.removeEvent(e, u)}
                                    />}
                            </TabPane>,
                            <TabPane key='tab3' tabId='3'>
                                {mainActiveTab == '3' && isLoaded &&
                                    <ActivityCalendar
                                        onSiteUserToggle={(e) => this.onSiteUserToggle(e)}
                                        onMonthChange={(e, y) => this.onMonthChange(e, y)}
                                        activityData={data}
                                        history={this.props.history}
                                        mode={mode}
                                        isCollapsible={true}
                                        engineerSiteToggle={engineerSiteToggle && engineerSiteToggle}
                                        mainActiveYear={mainActiveYear && mainActiveYear}
                                        mainActiveMonth={mainActiveMonth && mainActiveMonth}
                                        stations={this.props.header.assets}
                                        users={users}
                                        jobList={jobList}
                                        eventTypes={this.filterList(eventTypes, 'wcTypes')}
                                        workStatuses={workStatuses}
                                        calEventTypes={calEventTypes}
                                        customerWithSites={customerWithSites}
                                        saveEvent={(e) => this.saveEvent(e)}
                                        rowOrder={rowOrder}
                                        hideChecked={hideChecked}
                                        removeEvent={(e, u) => this.removeEvent(e, u)} />}
                            </TabPane>
                            ]
                        :
                        <OverlayLoader />}
                    </TabContent>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(mapStateToProps, null, null, { withRef: true })(ActivityContainer)
