import React from 'react';
import { ApiPost } from '../api';
import moment from 'moment';

function getExportData(stationId, day, month, year, stationMetrics, stationList) {
    var station = stationList.find(x => x.id == stationId),
        stationName = station.name,
        stationIndustry = station.industryId
    console.log('exporting for ' + stationName)
    console.log({ stationId, day, month, year, stationMetrics, stationList })
    var exportData = [];
    var tMonth = parseInt(month);
    var tDay = parseInt(day);
    if (day != 0) {
        var startDate = (tMonth + '/' + tDay + '/' + year + ' 00:00:00');
        var endDate = moment(startDate).add(1, 'd').add(30, 'm');
    }
    else if (month != 0) {
        var startDate = (tMonth + '/01/' + year + ' 00:00:00');
        var endDate = moment(startDate).add(1, 'M').add(30, 'm');
    }
    else {
        var startDate = ('01/01/' + year + ' 00:00:00');
        var endDate = ('12/31/' + year + ' 23:59:59');
    }
    var dataSettings = {
        fromDate: moment(startDate).toISOString(),
        toDate: moment(endDate).toISOString(),
        exportValueType: 'LAST',
        stationIds: stationId,
        groupRate: '30m',
        reportType: 6,
        metricIds: null,
        industryId: stationIndustry
    }
    ApiPost('Monitoring', 'GetHistoricScada', dataSettings)
    .then((data) => {
        exportData = [];
        data.historicData.results.map(x => {
            if (x.series) {
                x.series.map(y => {
                    exportData = exportData.concat(y);
                })
            }
        })
        downloadData(exportData, startDate, endDate, stationName, true, stationMetrics, stationIndustry);
    })

}

function downloadData(exportData, startDate, endDate, name, last, stationMetrics, stationIndustry) {

    var dataTimeGrouped = [];
    var csv;
    let title = ["Date"];

    exportData[0].values.map(i => {
        dataTimeGrouped[i[0]] = [];
        dataTimeGrouped[i[0]].push(moment.utc(i[0]).format('DD/MM/YYYY HH:mm:ss'));
    })

    exportData.map(i => {
        i.metricName = stationMetrics[parseInt(i.tags.MetricId)].metricName
    })

    exportData.sort((a, b) => a.metricName.localeCompare(b.metricName))

    exportData.map(i => {
        //console.log('stationMetrics[parseInt(i.tags.MetricId)].', stationMetrics[parseInt(i.tags.MetricId)])
        //console.log('i.tags.MetricId', i.tags.MetricId)
        title.push(stationMetrics[parseInt(i.tags.MetricId)].metricName);
        var tPrev = 0;
        i.values.map(x => {
            var type = i.columns[1], tGroup = x[0]
            if (!dataTimeGrouped[tGroup]) {
                var d = new Date(tGroup)
                var remainder = 30 - (d.getMinutes() % 30)
                var remainderS = 60 - (d.getSeconds() % 60)

                if (remainder == 1) {
                    d.setMinutes(d.getMinutes() + 1)
                    d.setSeconds(0)
                    tGroup = d.valueOf()
                }
            }
            if (x[1]) {
                if (dataTimeGrouped[tGroup]) {
                    dataTimeGrouped[tGroup].push(x[1])
                    if (type == 'first') {
                        if (tPrev != 0) {
                            var delta = x[1] - tPrev
                            dataTimeGrouped[tGroup].push(delta)
                        }
                        else {
                            title.push(stationMetrics[parseInt(i.tags.MetricId)].metricName + ' Delta');
                            dataTimeGrouped[tGroup].push(0)
                        }
                        tPrev = x[1];
                    }
                }
                else {
                    console.log('data missing', tGroup)
                }
            }
            else {
                dataTimeGrouped[tGroup].push('')
                if (type == 'first') {
                    dataTimeGrouped[tGroup].push('')
                }
            }

        })
    })

    csv += title.join(",") + "\r\n";

    Object.keys(dataTimeGrouped).map(time => {
        let row = dataTimeGrouped[time].join(",");
        csv += row + "\r\n";
    })

    csv = csv.replace("undefined", "");

    var fileName = name + ' export ' + moment(startDate).format('DD-MM-YYYY') + ' - ' + moment(endDate).format('DD-MM-YYYY');
    var a = window.document.createElement("a");
    var data = new Blob([csv], { type: 'text/csv' });
    a.href = window.URL.createObjectURL(data);
    a.download = fileName + ".csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}


const HistoricDataExport = (props) => {
    getExportData(props.stationId, props.day, props.month, props.year, props.metrics, props.stationList)
    return (
        true
    );
}

export default HistoricDataExport;