import React, { Component } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Modal, ModalFooter, ModalBody, Input, ModalHeader } from 'reactstrap'
import classnames from 'classnames'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import AlertRules from '../../AlertRules/components/AlertRules'
import Scheduler from '../../AlertRules/components/Scheduler'
import ConditionSelector from '../../AlertRules/components/ConditionSelector'
import Collapsible from 'react-collapsible'
import { listRequest } from '../../../configuration/enums'
import { ApiGet, ApiPost } from '../../../api'

export default class AlertPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false, activeTab: '1', mainActiveTab: '1', listData: null, complete: false, alertRules: null,
            ret: {
                name: null, scope: 0, conditionType: 0, message: null, messageTypeId: 0, messageMetricId: 0,
                messageControllerId: 0, alertSchedule: null, conditions: "", id: 0, stationId: this.props.station.id
            }
        }
    }

    componentDidMount() {
        this.getAlertRules()
        this.getListData()
    }

    getAlertRules() {
        var alertSpec = {
            id: this.props.station.id,
        }

        ApiGet('AlertRules', 'GetRules', alertSpec)
        .then((data) => {
            this.setState({ alertRules : data })
        })
    }

    getListData() {
        var listsToGet = [listRequest.UniversalMetric, listRequest.controllers];
        ApiGet('Lists', 'GetList', { ListIds: JSON.stringify(listsToGet) })
        .then((data) => {
            var tempList = listsToGet;
            Object.keys(data).map(x => {
                tempList[x] = data[x]
            })
            this.setState({ listData: tempList, complete: true });
        })
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab, selectedOption: 2 });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    removeAlert(id) {
        console.log('removing alert id ' + id)
        var t = this.state.alertRules
        t = t.filter(x => x.id != id)
        this.setState({alertRules : t})
    }

    valChange(val, type) {

        var tR = this.state.ret
        tR[type] = val
        this.setState({ret: tR}, () => console.log('set',this.state.ret))

        //this.props.rec.onChange(JSON.stringify(tCond))
    }

    saveAlert() {
        ApiPost('AlertRules', 'SaveAlert', this.state.ret)
        .then((data) => {
            console.log('data', data)
            this.setState({ open: !this.state.open })
            this.getAlertRules()
        })
    }

    editAlert(id) {
        var r = this.state.alertRules.find(x => x.id == id)
        this.setState({ret: r, open: !this.state.open})
    }

    closeModal() {
        var t = {
            name: null, scope: 0, conditionType: 0, message: null, messageTypeId: 0, messageMetricId: 0,
            messageControllerId: 0, alertSchedule: null, conditions: "", id: 0, stationId: this.props.station.id
        }
        this.setState({ open: !this.state.open, ret: t })
    }

    getModal() {

        const { name, scope, conditionType, message, messageTypeId, messageMetricId, messageControllerId, alertSchedule, conditions } = this.state.ret
        var lists = {
            scope: [{ id: 1, name: 'Device' }, { id: 2, name: 'Device Type' }],
            calculation: [{ id: 0, name: 'None' }, { id: 1, name: 'Average' }, { id: 2, name: 'Formula' }],
            schedule: [{ id: 1, name: 'Morning and weekends' }, { id: 2, name: 'Weekends' }, { id: 3, name: 'Out of office' }, { id: 4, name: 'Night' }, { id: 5, name: 'Custom' }],
            conditions: [{ id: 1, name: 'All conditions required to alert (AND)' }, { id: 2, name: 'Max' }, { id: 3, name: 'Min' }],
            msgTypes: [{ id: 1, name: 'Static' }, { id: 2, name: 'CSV List' }, { id: 3, name: 'Controller' }],
            currentAlerts: ['VMP400', 'Gen Temp', 'Avg Wind Speed']
        }

            //< Row >
            //<Col sm={2}>Type</Col>
            //<Col sm={10}><IdNameDropdown value={messageTypeId} options={lists.msgTypes} zeroText='Select' valueField="id" displayField="name" onChange={(e) => this.valChange(e, 'messageTypeId')} /></Col>
            //                        </Row >
            //<Row>
            //    <Col sm={2}>Metric</Col>
            //    <Col sm={10}><IdNameDropdown value={messageMetricId} options={this.state.listData[listRequest.UniversalMetric]} zeroText='Select' valueField="id" displayField="name" onChange={(e) => this.valChange(e, 'messageMetricId')} /></Col>
            //</Row>
            //<Row>
            //    <Col sm={2}>Controller</Col>
            //    <Col sm={10}><IdNameDropdown value={messageControllerId} options={this.state.listData[listRequest.controllers]} zeroText='Select' valueField="id" displayField="name" onChange={(e) => this.valChange(e, 'messageControllerId')} /></Col>
            //</Row>

        return (
            <Modal isOpen={this.state.open} autoFocus={false} className='alertRuleModal'>
                <ModalHeader>Alert Rules</ModalHeader>
                <ModalBody style={{ minHeight: '80vh' }}>
                    <Row>
                        <Col sm={2}>Scope</Col>
                        <Col sm={10}><IdNameDropdown value={scope} options={lists.scope} zeroText='Select' valueField="id" displayField="name" onChange={(e) => this.valChange(e, 'scope')} /></Col>
                    </Row>
                    <Row>
                        <Col sm={2}>Name</Col>
                        <Col sm={10}><Input defaultValue={name} onChange={(e) => this.valChange(e.target.value, 'name')} /></Col>
                    </Row>
                    <Scheduler list={lists.schedule} rec={alertSchedule} onChange={(e) => this.valChange(e, 'alertSchedule')} />
                    <Row>
                        {this.state.complete && <Col sm={12}>
                            <Nav tabs key='caseTabs' style={{ marginTop: '20px' }}>
                                <NavItem >
                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1', 'sub') }}>Conditions</NavLink>
                                </NavItem>
                                <NavItem >
                                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2', 'sub') }}>Message</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent key='casesTabs' activeTab={this.state.activeTab}>
                                <TabPane tabId='1'>
                                    <Row>
                                        <Col sm={2}>Type</Col>
                                        <Col sm={10}><IdNameDropdown value={conditionType} options={lists.conditions} zeroText='Select' valueField="id" displayField="name" onChange={(e) => this.valChange(e, 'conditionType')} /></Col>
                                    </Row>
                                    <div style={{marginLeft:'-15px'}}><ConditionSelector list={this.state.listData[listRequest.UniversalMetric]} rec={conditions} onChange={(e) => this.valChange(e, 'conditions')} /></div>
                                </TabPane>
                                <TabPane tabId='2'>
                                    <Row>
                                        <Col sm={2}>Message</Col>
                                        <Col sm={10}><Input type='textarea' rows="15" defaultValue={message} onChange={(e) => this.valChange(e.target.value, 'message')} /></Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>}
                    </Row>
                </ModalBody>
                <ModalFooter style={{ justifyContent: 'space-between' }}>
                    <button className='btn btn-primary' onClick={() => this.closeModal()}>Cancel</button>
                    <button className='btn btn-primary' onClick={() => this.saveAlert()}>Save</button>
                </ModalFooter>
            </Modal>
            )
    }

    render() 
    {

        return (
            <Collapsible trigger='Alerting Rules' triggerClassName="card-heading" triggerOpenedClassName="card-heading" transitionTime={200} >

                <div className='alertRuleRow'>
                    {
                        this.state.alertRules && this.state.alertRules.map(x => {
                            return (<div className='fav-badge alertBadge' key={'al'+x.id} >
                                <span onClick={() => this.editAlert(x.id)}>{x.name}</span>
                                <i className='fas fa-times' style={{ paddingLeft: '5px' }} onClick={() => this.removeAlert(x.id)}/>
                            </div>)
                        })
                    }
                    <div className='fav-badge alertBadge' onClick={() => this.setState({ open: !this.state.open })} >
                        <span>New</span>
                        <i className='fas fa-plus' style={{ paddingLeft: '5px' }} />
                    </div>
                </div>

                {this.state.open && this.getModal()}

            </Collapsible>
        );
    }
}