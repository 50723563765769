import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { listRequest } from '../../../configuration/enums'
import { ApiGet, ApiPost } from '../../../api'
import moment from 'moment'
import helpers from '../../../utils/helpers'

class WorkHoursTracker extends Component {

	constructor(props) {
        super(props);
        this.state = { timesheet: null, duration: "0h 0m", completePrev: false }
    }

    componentDidMount() {
        ApiGet('WorkHoursTracker', 'GetTimesheet')
        .then((data) => {
            //this.setState({ timesheet: data }, () => { this.getTime(); this.setCustomTime(); console.log('timesheet', this.state.timesheet) })
            if (data.lday && this.props.completePrev) {
                this.setState({ timesheet: data.lday, completePrev: true, editTime: true }, () => { this.setCustomTime() })
            }
            else if (data.today) {
                this.setState({ timesheet: data.today }, () => { this.getTime(); this.setCustomTime() })
            }
            else {
                this.setState({ timesheet: { startTime: null, endTime: null } }, () => { this.getTime(); this.setCustomTime() })

            }
        });
        this.timerId = setInterval(this.updateTimer.bind(this), 1000)
    }

    logTime(type) {
        ApiPost('WorkHoursTracker', 'LogTime', {type})
        .then((data) => {
            this.setState({ timesheet: data }, () => { this.getTime(); this.setCustomTime() })
        });
    }

    setCustomTime() {
        this.setState({
            customStart: moment.utc(this.state.timesheet.startTime).format('HH:mm'),
            customEnd: moment.utc(this.state.timesheet.endTime ? this.state.timesheet.endTime : moment.utc(this.state.timesheet.startTime).add(1, 'hour')).format('HH:mm')
        })
    }

    saveCustomTime() {
        var startTime = moment.utc(this.state.timesheet.startTime, 'YYYY-MM-DD').format('DD/MM/YYYY')
        var start = moment(startTime + ' ' + this.state.customStart, 'DD/MM/YYYY HH:mm'), end = moment(startTime + ' ' + this.state.customEnd, 'DD/MM/YYYY HH:mm')
        if (start < end) {
            ApiPost('WorkHoursTracker', 'SaveCustomTime', { start: start.format('DD/MM/YY HH:mm'), end: end.format('DD/MM/YY HH:mm'), id: this.state.timesheet.workHoursTimesheetId })
            .then((data) => {
                this.setState({ timesheet: data, editTime: false, timeError: false, completePrev: false, modified: true }, () => { this.getTime(); this.setCustomTime() })
            })
        }
        else {
            this.setState({timeError: true})
        }
    }

    updateTimer() {
        if (this.state.timesheet && this.state.timesheet.startTime && !this.state.timesheet.endTime) {
            var start = moment.utc(this.state.timesheet.startTime)
            var t = helpers.parseDuration(start, moment.utc(moment()), false, true)
            this.setState({ duration: t })
        }
    }

    getTime() {
        var end = moment.utc(this.state.timesheet.endTime), start = moment.utc(this.state.timesheet.startTime), t = "0h 0m"
        if (this.state.timesheet.endTime) {
            var t = helpers.parseDuration(start, end)
            t = 'Day Total: ' + (t != '' ? t : '0m')
        }
        else if (this.state.timesheet.endTime && this.state.timesheet.startTime) {
            var t = helpers.parseDuration(start, moment.utc(moment()))
            t = (t != '' ? t : '0m')
        }
        this.setState({duration: t})
    }

    componentWillUnmount() {
        clearInterval(this.timerId)
    }

    render() {
        const { timesheet, duration, editTime, timeError, completePrev, customStart, customEnd, modified } = this.state

        if (timesheet) {
            if (editTime) {
                var tzOffset = moment(timesheet.startTime).utcOffset()
                var mStart = modified ? customStart : moment(timesheet.startTime).add(tzOffset, 'm').format('HH:mm')
                var mEnd = modified ? customEnd : moment(timesheet.endTime).add(tzOffset, 'm').format('HH:mm')
                return (
                    <div >
                        <Row className={'whTracker ' + (timeError && 'timeError')}>
                            <Col xs={4} sm={4}>
                                <Row>
                                    <input type='time' className='form-control' defaultValue={mStart} style={{ padding: '0.25rem' }} onChange={(e) => this.setState({ customStart: e.target.value })} />
                                </Row>
                            </Col>
                            <Col xs={1} sm={1}><b>to</b></Col>
                            <Col xs={4} sm={4}>
                                <Row>
                                    <input type='time' className='form-control' defaultValue={mEnd} style={{ padding: '0.25rem' }} onChange={(e) => this.setState({ customEnd: e.target.value })} />
                                </Row>
                            </Col>
                            <Col xs={3} sm={2}>
                                <button type="button" className="btn btn-primary" onClick={() => this.saveCustomTime()}>Save</button>
                            </Col>
                            {completePrev && <Col xs={12} style={{ color: '#f44336', textAlign:'center' }}>Please complete timesheet for {moment(timesheet.startTime).format('DD/MM/YY')}</Col>}
                        </Row>
                    </div>
                    )
            }
            else {
                return (
                    <Row className='whTracker'>
                        <Col xs={7} sm={7}>
                            <span>{duration}</span>
                        </Col>
                        {!timesheet.endTime &&
                            <Col xs={3} sm={5}>
                                <button type="button" style={{float:'right'}} className="btn btn-primary" onClick={() => this.logTime(0)}>{timesheet.startTime ? 'End Day' : 'Start Day'}</button>
                            </Col>
                        }
                        {timesheet.startTime && timesheet.endTime &&
                            <Col xs={5} sm={5}>
                                <button type="button" className="btn btn-primary" style={{ float: 'right', marginLeft: '5px' }} onClick={() => this.setState({ editTime: true })}>Edit</button>
                                {timesheet.modified == null && <button type="button" className="btn btn-primary" style={{ float: 'right' }} onClick={() => this.logTime(1)}>Resume</button>}
                            </Col>
                        }
                    </Row>
                )
            }
        }
        else {
            return null
        }
	}
}

export default WorkHoursTracker
