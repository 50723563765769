import React, { Component } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { listRequest } from '../../../configuration/enums';
import MasterDetail from 'MasterDetail'
import FilterWarning from '../../../components/FilterWarning';
import Dash from '../../Dashboard/components/DashContainer';
export const API_PATH = process.env.SERVER_PATH + '/api'

export default class IncidentActions extends Component {

    pillColors = ['danger', 'warning','warning','info']
    eventSelection = [{id:1, title:"Today"},{id:2, title:"7 days"},{id:3, title:"30 days"},{id:4, title:"All upcoming"}]
    gridProps = {noDataText:<span style={{display:'grid',textAlign:'center'}}><i className="fal fa-check-circle" style={{fontSize:'50px', color:'green' }}></i>No Actions</span>}

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            complete: false,
            tabRowCount: [0,0,0,0],
            eventFilter: 1
        };
        this.updateTabRowCount = this.updateTabRowCount.bind(this);
    }

    getPill(tabid) {
        const v = this.state.tabRowCount[tabid-1];
        let ret = null;
        let cl = "badge badge-pill badge-primary"
        if (this.pillColors[tabid-1]) {
            cl = "badge badge-pill badge-" + this.pillColors[tabid-1]
        }
        if (v > 0) {
            ret = <span className={cl}>{v}</span>
        }
        return ret;
    }

    getEventsGrid(f,filt) {
        var s = f.slice(0,f.length-1)
        s.push(
            {...f[f.length-1],location: '/Events/'  }
        )
        var updateTabRowCount = this.updateTabRowCount
        if (filt == 4) { 
            return <MasterDetail model={'opAction41'} path={'/OpActions/GetOpenActions/44/'} apiPath={API_PATH} fields={s} root={root} master={true} gridButton={false} gridProps={this.gridProps}></MasterDetail>
        }
        else if (filt == 3) {
            return <MasterDetail model={'opAction42'} path={'/OpActions/GetOpenActions/43/'} apiPath={API_PATH} fields={s} root={root} master={true} gridButton={false} gridProps={this.gridProps}></MasterDetail>
        } else if(filt == 2) {
            return <MasterDetail model={'opAction43'} path={'/OpActions/GetOpenActions/42/'} apiPath={API_PATH} fields={s} root={root} master={true} gridButton={false} gridProps={this.gridProps}></MasterDetail>
        } else {
            return <MasterDetail model={'opAction44'} path={'/OpActions/GetOpenActions/41/'} apiPath={API_PATH} onDataLoad={updateTabRowCount(4)} fields={s} root={root} master={true} gridButton={false} gridProps={this.gridProps}></MasterDetail>
        }
    }

    toggle(activeTab) {
        if (this.state.activeTab !== activeTab) {
            this.setState({activeTab});
        }
    }

    updateTabRowCount(tabid){
        return (cnt, rec) => {
            let copy = JSON.parse(JSON.stringify(this.state.tabRowCount))
            copy[tabid-1] = cnt
            this.setState({ tabRowCount:copy })
        }
    }

    goNav(rec, location) {
        this.props.history.push(location)
    }

    setEventFilter(filterId) {
        this.setState({eventFilter: parseInt(filterId)});
    }

    render() {
        var updateTabRowCount = this.updateTabRowCount
        var f = [
            { label: "Id", fieldId: "id", width: 70, hidden: true, type: 'text'},
            { label: "Time", fieldId: "actionsTimestamp", type: 'datetime', canFilter: false},
            { label: "Asset", fieldId: "assetName", type: 'select' },
            { label: "Description", fieldId: "description", type: 'text', canFilter: true},
			{ label: "AllocatedTo", fieldId: "allocatedTo", canFilter: true, list: listRequest.operators},
            { label: "Open For", fieldId: "openForDescrip", type: 'text' },
            { label: "", fieldId: "title", display: 'none' },
            { label: "", fieldId: "navButton", type: 'navButton', display: 'grid', typeProps: { location: '/Incidents/' }, width: 70 },
            { label: "", fieldId: "funcButton", type: 'funcButton', width: 70, typeProps: { label: 'Scada', func: (rec) => { return this.props.history.push('/Scada/' + rec._original.stationId, { history: null }) } } }
        ];

        var f2 = [
            { label: "Id", fieldId: "id", width: 70, hidden: true, type: 'text' },
            { label: "Time", fieldId: "actionsTimestamp", type: 'datetime', canFilter: false },
            { label: "Asset", fieldId: "assetName", type: 'select' },
            { label: "Description", fieldId: "description", type: 'text', canFilter: true },
            { label: "AllocatedTo", fieldId: "allocatedTo", canFilter: true, list: listRequest.operators },
            { label: "Open For", fieldId: "openForDescrip", type: 'text' },
            {
                label: "", fieldId: 'navButton', type: 'funcButton', width: 70, typeProps: {
                    label: "View", func: (rec) => {
                        var i = rec._original.latestWC
                        var url = i.id ? i.response == 99 ? "/Events/" + i.id + "/2" : "/Workcards/" + i.id : "/Incidents/" + rec._original.id
                        return this.props.history.push(url, {history : null})
                    }
                }
            },
        ];

        const preloadedWidgets =
            [
                { i: '535517', x: 0, y: 0, w: 8, h: 1, type: '34', period: null, metric: null, params: null },
                { i: '535518', x: 8, y: 0, w: 4, h: 1, type: '35', period: '7', metric: null, params: null },
            ]

        const preloadedDashInfo = { dashId: 998, tabId: 0, tabName: 'OnsiteDash', dashType: 0, fullDash: false, cols: 12, rowHeight: 170 }

        const { activeTab } = this.state
        return (
            <div>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div className='titleBlock'>Actions</div>
                    <FilterWarning style={{width:'50%'}}/>
                </div>
                <div>
                    <Dash preloadedWidgets={preloadedWidgets} preloadedDashInfo={preloadedDashInfo} hideEdit={true} hideTitle={true} />
                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { this.toggle('1') }}>Unallocated{this.getPill(1)}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { this.toggle('2') }}>My Actions{this.getPill(2)}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { this.toggle('3') }}>Open Actions{this.getPill(3)}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { this.toggle('4') }}>Scheduled Events{this.getPill(4)}</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <MasterDetail model={'opAction'} path={'/OpActions/GetOpenActions/10/'} apiPath={API_PATH} onDataLoad={updateTabRowCount(1)} gridButton={false} fields={f} root={root} master={true} titleField={'title'} gridProps={this.gridProps} refreshInterval={120}></MasterDetail>
                    </TabPane>
                    <TabPane tabId="2">
                        <MasterDetail model={'opAction2'} path={'/OpActions/GetOpenActions/20/'} apiPath={API_PATH} onDataLoad={updateTabRowCount(2)} gridButton={false} fields={f} root={root} master={true} titleField={'title'} gridProps={this.gridProps}></MasterDetail>
                    </TabPane>
                    <TabPane tabId="3">
                        <MasterDetail model={'opAction3'} path={'/OpActions/GetOpenActions/30/'} apiPath={API_PATH} onDataLoad={updateTabRowCount(3)} gridButton={false} fields={f} root={root} master={true} titleField={'title'} gridProps={this.gridProps}></MasterDetail>
                    </TabPane>
                    <TabPane tabId="4">
                        {/*<div className="row form-group" style={{marginTop:'5px'}}>
                            <div style={{marginLeft:'auto', marginRight:'15px'}}>
                                <button type="button" className="btn btn-info" onClick={() => this.props.history.push("/Events/New/2", { history: [{ id: -1, model: "opAction", label: "opAction", path: "/Actions", mdFilter: [] }] })}><i className="fa fa-plus"></i> Add Event</button>
                            </div>
                        </div>*/}
                        {/*this.getEventsGrid(f2,this.state.eventFilter)*/}
                        <div style={{ width: '100%', textAlign: 'center', height:'50px', cursor:'pointer' }} onClick={()=>this.props.history.push('/Calendar')}><h4>Scheduled events are managed in the calendar</h4></div>
                    </TabPane>
                </TabContent>
                <div>
                    
                </div>
            </div>
        );
    }
}
